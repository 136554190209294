import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  Slider,
  Stack,
  styled,
  Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from "react-redux";
import { RootState } from "../store";



interface Props {
  open: boolean;
  onClose: () => void;
}

const MintPendingDialog: React.FC<Props> = ({ open, onClose }) => {


  const stats = useSelector((state: RootState) => state.stats);
  const mint = useSelector((state: RootState) => state.mint);


  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Transaction created
      </DialogTitle>
      <DialogContent>
        <Typography variant="caption" noWrap component="h1">
          {mint.transactionHash}
        </Typography>
        <DialogContentText sx={{ mt: 2 }}>
          Waiting for network confimration, you don't need to keep this open.
        </DialogContentText>
        <DialogContentText sx={{ mb: 2 }}>
          You can also track you transaction on etherscan {' '}
          <Link target="_blank" href={`
              ${process.env.GATSBY_ETHERSCAN_DOMAIN}/tx/${mint.transactionHash}`}>here</Link>.
        </DialogContentText>
        {
          mint.mintPending ? <LinearProgress /> : (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h5" noWrap>Transaction confirmed </Typography>
              <CheckIcon color="success" fontSize="large"/>
            </Stack>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog >
  );
};

export default MintPendingDialog;