import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  Slider,
  Stack,
  styled,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { BigNumber } from "bignumber.js";

import { actions } from '../services/mint';

const Root = styled('div')({

});


interface Props {
  open: boolean;
  onClose: () => void;
  onMintStarted: () => void;
}

const MintDialog: React.FC<Props> = ({ open, onClose, onMintStarted }) => {

  const [ count, setCount ] = useState(1);
  const [ maxMint, setMaxMint ] = useState(5);
  const dispatch = useDispatch();

  const stats = useSelector((state: RootState) => state.stats);
  const mint = useSelector((state: RootState) => state.mint);
  const wallet = useSelector((state: RootState) => state.wallet);

  const onCountChange = (event: Event, newValue: number | number[]) => {
    setCount(newValue as number);
  }


  useEffect(() => {
    const max = Math.min(stats.totalSupply - stats.circulatinSupply, 5);
    setMaxMint(max);
  }, [ stats.circulatinSupply, stats.totalSupply ]);


  const getPrice = () => {
    const finalTokenId = stats.circulatinSupply + count;
    const currRangeMax = stats.tokensPerRange + (stats.currentRange * stats.tokensPerRange) - 1;
    const currPrice = new BigNumber(stats.currentPrice);
    const startPrice = new BigNumber(stats.startPrice);
    const priceIncrement = new BigNumber(stats.priceIncrement);

    if (finalTokenId <= currRangeMax) {
      return currPrice.times(count).toString();
    }

    const tokensInNextRange = finalTokenId - currRangeMax;
    const tokensInCurrRange = count - tokensInNextRange;


    const priceCurrRange = startPrice.plus(priceIncrement.times(stats.currentRange));
    const priceNextRange = startPrice.plus(priceIncrement.times(stats.currentRange + 1));


    const totalCurrRange = priceCurrRange.times(tokensInCurrRange);
    const totalNextRange = priceNextRange.times(tokensInNextRange);

    return totalCurrRange.plus(totalNextRange).toString();
  }

  const getValueText = (value: number) => {
    return value.toString();
  }

  const onMint = () => {
    dispatch(actions.mintTokens({
      count,
      price: new BigNumber(getPrice()),
      onMintStarted,
    }));
  }

  const wrongNetwork = wallet.ethNetwork !== process.env.GATSBY_ETH_NETWORK_ID;
  const presaleMode = process.env.GATSBY_SALE_MODE === 'presale';

  const getContent = () => {
    if (wrongNetwork) {
      return (
        <React.Fragment>
          <DialogTitle>
            Wrong Network
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You wallet is on the wrong Ethereum network. Please switch to "{process.env.GATSBY_ETH_NETWORK}" network.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <DialogTitle>
            How many HAYAPE's do you want to mint?
          </DialogTitle>
          <DialogContent sx={{ pt: 2, pb: 2 }}>
            <Typography color="text.secondary" gutterBottom>
              Make sure you use the same wallet that you whitelisted with.
            </Typography>
            <Typography color="error">{mint.error}</Typography>

            <Slider
              value={count}
              onChange={onCountChange}
              getAriaValueText={getValueText}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={maxMint}
              sx={{ mt: 6, mb: 1 }}
            />

            <Stack>
              <Typography align="center">
                {getPrice()} ETH
              </Typography>

              <Typography align="center" variant="caption" color="text.secondary">
                During pre-sale, each whitelisted wallet can mint only once,<br />so you better mint more than one HayApe now.
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton onClick={onMint} autoFocus loading={mint.pendingWalletConfirm}>
              Mint
            </LoadingButton>
          </DialogActions>
        </React.Fragment>
      );
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      {getContent()}
    </Dialog >
  );
};

export default MintDialog;