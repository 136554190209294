import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, styled } from '@mui/material';
import { actions as walletActions } from '../services/wallet';
import MintDialog from "./MintDialog";
import MintPendingDialog from "./MintPendingDialog";
import { RootState } from "../store";

const Root = styled('div')({

});

const MintButton: React.FC = () => {
  const stats = useSelector((state: RootState) => state.stats);
  const [ mintModalOpen, setMintModalOpen ] = useState(false);
  const [ mintPendingOpen, setMintPendingOpen ] = useState(false);

  const dispatch = useDispatch();

  const onMintClick = () => {
    dispatch(
      walletActions.connectWallet({
        onConnected: () => {
          setMintModalOpen(true);
        }
      })
    );
  }

  const closeMintModal = () => {
    setMintModalOpen(false);
  }

  const closeMintPending = () => {
    setMintPendingOpen(false);
  }

  const onMintStarted = () => {
    closeMintModal();
    setMintPendingOpen(true);
  }

  return (
    <Root>
      <Button variant="contained" color="secondary" size="large" onClick={onMintClick}>
        Mint Now ({stats.currentPrice} eth)
      </Button>

      <MintDialog open={mintModalOpen} onClose={closeMintModal} onMintStarted={onMintStarted} />
      <MintPendingDialog open={mintPendingOpen} onClose={closeMintPending} />
    </Root>
  );
};

export default MintButton;